import React, { useEffect, useState } from 'react'

// images
import resumePhoto from '../images/resume.jpeg';
import ProjectPortfolio from '../images/Project/portfolio.png';
import Snake from '../images/Project/snake.png';
import ImgMes from '../images/Project/ImgMes.jpeg';
import HomeDeco from '../images/Project/HomeDeco.png';
import virtual_mouse_keyboard from '../images/Project/virtual-mouse-keyboard.png';

// components
import Project from '../components/Project';
import ViewProject from '../components/ViewProject';


import { Waypoint } from 'react-waypoint';
import Wave from 'react-wavify'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: "none", duration: 2 });
ScrollTrigger.config({
    autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
});

const Home = () => {
    const [LoadingPercentageDisplay, setLoadingPercentageDisplay] = useState(0)
    const [TitleTextList, setTitleTextList] = useState(["a Frontend Developer", "a Backend Developer", "a Programming Enthusiast"])
    const [CurrentTitleDisplay, setCurrentTitleDisplay] = useState("");
    const [width, setWidth] = useState(document.body.clientWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [page, setPage] = useState("main");
    const [ResumePhotoHeight, setResumePhotoHeight] = useState(0);
    const [ViewProjectID, setViewProjectID] = useState(-1);
    const [projectData, setprojectData] = useState([
        {
            "title": "My Portfolio Website",
            "image": ProjectPortfolio,
            "description": "I wanted to create a frontend project to test out a few services such as Netlify and a few Javascript Packages such as Waypoints, Gsap and ScrollTrigger. Thus, I decided to create this Personal Portfolio website for that reason. I am using the React framework to create this website and Netlify to host it. I have also bought the domain using Namecheap.",
            "tags": ["HTML", "CSS", "JS", "React", "Netlify", "Namecheap"],
            "type": "Personal"
        },
        {
            "title": "Game clicking bot",
            "image": "https://via.placeholder.com/320x213/?text=No+Image+for+this+Project",
            "description": "I was exploring around youtube when I discoved a video on using the Python library, PIL, to get the colour of a pixel. Feeling curious, I tried it out and have created a bot that will search for a specific colour on a section of the screen and click it when it appears. Dispite the bot working as intended, I felt that it could be improved on. Thus, I have researched more on it which lead me to Template Matching using OpenCV. With this library, I successfully created another bot that can adapt to more situations as I am able to set the confidence level for the matching process.",
            "tags": ["Python", "Pixel Colour matching using PIL", "OpenCV Template matching", "pyautogui clicking"],
            "type": "Personal"
        }, {
            "title": "Virtual Mouse and Keyboard using hand motions",
            "image": virtual_mouse_keyboard,
            "description": "Learning about using CV2 to capture the webcam from school, I wanted to use it to create a 'Virtual Mouse and Keyboard' that will detect my hand motions to complete a specific task. I am also using MediaPipe for their Hand Tracking Machine Learning model, this will use the image taken from my webcam using CV2 and track for my hand motions.",
            "tags": ["Python", "MediaPipe Machine Learning", "CV2 Webcam capture"],
            "type": "Personal"
        }, {
            "title": "Snake Game",
            "image": Snake,
            "description": "The school wanted us to discover and learn new projects as our assignment. Thus, being relatively new to Python at the time, I decided to use it to create a simple Snake game with the Turtle Library. Through this Project, I have learned the basics of Python and wanted to explore more on it, hence creating my other projects.",
            "tags": ["Python", "Turtle"],
            "type": "School"
        }, {
            "title": "Imgmes (Social Media Mobile Application)",
            "image": ImgMes,
            "description": "my group and I was tasked to create a mobile application as our assignment. Wanting a challenge, we decided to create a social media application using Java that uses firebase as our database. We encountered many problems such as Image uploading and updating live data to the application.  But we endured through and have successfully created a working application and have exported the apk file for it. Testing out the apk file from our phones, we were amazed that we have created a working social media application that contains a posting and messaging feature.",
            "tags": ["Java", "Mobile application", "firebase"],
            "type": "School"
        }, {
            "title": "Home Deco (Ecommerce Website)",
            "image": HomeDeco,
            "description": "Home Deco is the name of an Ecommerce website that my partner and I have created for one of our assignment. the website contains features such as: Adding products into cart and purchasing them, Allowing the use of Paypal as a form of payment, Creating a user account and viewing past orders, entering the admin portal to review all of the orders available and to create/update products that are being listed down. This was initially a challenge for us as this was our first time being in contact with Java and Apache. But with our lecturer's guidance, we could understand how to use it and decided to include all of the advance requirements for it.",
            "tags": ["Java", "Java Server Pages", "Apache", "Paypal", "Servlets"],
            "type": "School"
        }
        , {
            "title": "Focus Reminder application",
            "image": "https://via.placeholder.com/320x213/?text=No+Image+for+this+Project",
            "description": "My project partner and I have came up with a focus reminder application that utilises the user's device equipped with a web camera, to ensure that he/she is focused on their work. This was due to discovering many students and adults who are on e-learning or Work From Home (WFH) are unable to focus while doing their work at home. Our application will use their device's camera and with the integration of machine learning and artificial intelligence, will turn on the Raspberry Pi's buzzer, LED and will also send an email and tweet as a form of alert to notify the user that they should be focusing on their work if his/her face is not detected on the webcam. ",
            "tags": ["IOT", "Python", "Raspberry Pi", "node red", "Machine Learning", "Facial Recognition"],
            "type": "School"
        }, {
            "title": "Fingerprint Door lock",
            "image": "https://via.placeholder.com/320x213/?text=No+Image+for+this+Project",
            "description": "The lecturer have given ten different project ideas to choose from. Wanting to try something new, my partner and I decided to go for a Fingerprint Door Lock. We also added in a few other features to enchance the final product, such as it turning on/off depending on whether there is someone near it, and if there are too many failed attempts, the owner will receive a twitter tweet and an email informing them of the situation. The owner can also refer to a mobile application that we have created to review it.",
            "tags": ["IOT", "Python", "Arduino", "Fingerprint reader"],
            "type": "School"
        }
    ])

    var runcount = 0
    useEffect(() => {
        if (runcount === 0) {
            loading();
            typing_animation_loop();
            window.addEventListener('resize', () => {
                Resize();
            })
            runcount++;
        }
    }, [])

    var oldWidth = width;

    const Resize = () => {
        if (page == "main") {
            try {
                // only refresh with width changes and not height
                if (oldWidth != document.body.clientWidth) {
                    console.log("refresh")
                    setTimeout(ScrollTrigger.refresh(), 1000);
                }

                about_resume_photo_resizing();
            } catch (e) {
                setPage("Project");
            }
        }

        oldWidth = document.body.clientWidth;
        setWidth(document.body.clientWidth);
        setHeight(window.innerHeight);
    }

    var then;
    var LoadingPercentage = 0;
    // first loading screen
    const loading = (now) => {
        if (!then) {
            then = now;
        }
        const delta = now - then;
        then = now;
        if (delta) {
            LoadingPercentage += 100 * (delta / 1000);
            LoadingPercentage = Math.round((LoadingPercentage + Number.EPSILON) * 100) / 100;
        }
        if (LoadingPercentage < 100) {
            requestAnimationFrame(loading);
        } else {
            LoadingPercentage = 100;
        }
        setLoadingPercentageDisplay(LoadingPercentage);
    }

    // after loading animation over
    useEffect(() => {
        if (LoadingPercentageDisplay >= 100) {
            document.getElementById("Loadingoverlay").className += "hidden";
            document.getElementById('canvas').style.display = "block";
            document.getElementById("navbar").style.display = "flex";


            document.getElementById('background').style.display = "block";

            about_resume_photo_resizing()

            // experience branch
            ExperienceBranch();

            // form field focus animations
            var form_field_array = document.getElementsByClassName("form-field");
            for (var i = 0; i < form_field_array.length; i++) {
                form_field_array[i].addEventListener('focus', (event) => {
                    event.target.parentNode.classList.add("form-field-animation-class");
                })

                form_field_array[i].addEventListener('blur', (event) => {
                    event.target.parentNode.classList.remove("form-field-animation-class");
                })
            }
            
            // project slider animations
            projectAnimation();

            // masthead animations for scrolltrigger
            mastheadScrollTrigger();

            // view project page
            var projects = document.getElementsByClassName("project-showcase");
            for (var i = 0; i < projects.length; i++) {
                projects[i].addEventListener("click", (event) => {
                    var element = event.target || event.srcElement;
                    do {
                        if (element.className != "project-showcase") {
                            element = element.parentNode
                        }
                    } while (element.className != "project-showcase")
                    setViewProjectID(element.id[element.id.length - 1]);
                })
            }

        }
    }, [LoadingPercentageDisplay])


    // Typing animation on the top of the home page
    var CurrentTitleText = "";
    // if Deleting === true means the text is being removed
    var Deleting = false;

    // main animation function loop
    const typing_animation_loop = () => {
        var timeoutmiliseconds = 0;
        for (var i = 0; i < TitleTextList.length; i++) {
            for (var x = 0; x < TitleTextList[i].length; x++) {

                // adding text
                timeoutmiliseconds += 150
                typing_animation_timeout(timeoutmiliseconds, TitleTextList, i, x);
                if (TitleTextList[i].length === x + 1) {
                    Deleting = true;
                }

                // removing text
                if (Deleting === true) {
                    // short pause before removing text
                    timeoutmiliseconds += 850
                    for (var a = 0; a < TitleTextList[i].length; a++) {
                        timeoutmiliseconds += 50
                        deleting_animation_timeout(timeoutmiliseconds);
                    }
                    Deleting = false
                }

                // if on last element of the array
                if (TitleTextList.length === i + 1 && TitleTextList[i].length === x + 1) {
                    // repeat the function again
                    setTimeout(() => {
                        typing_animation_loop();
                    }, timeoutmiliseconds);
                }
            }
        }
    }

    // function to add text
    const typing_animation_timeout = (timeoutmiliseconds, TitleTextList, i, x) => {
        setTimeout(() => {
            CurrentTitleText += TitleTextList[i][x]
            setCurrentTitleDisplay(CurrentTitleText);
        }, timeoutmiliseconds);
    }

    // function to remove text
    const deleting_animation_timeout = (timeoutmiliseconds) => {
        setTimeout(() => {
            CurrentTitleText = CurrentTitleText.substring(0, CurrentTitleText.length - 1)
            setCurrentTitleDisplay(CurrentTitleText);
        }, timeoutmiliseconds);
    }

    const about_resume_photo_resizing = () => {
        // about resume photo resizing
        var about_text = document.getElementsByClassName("about-text")[0];
        var about_text_style = about_text.currentStyle || window.getComputedStyle(about_text);
        var ResumePhotoHeight = parseFloat(about_text_style.height) * 0.80;
        setResumePhotoHeight(ResumePhotoHeight);
    }

    // experience remove first branch
    const ExperienceBranch = () => {
        var ExperienceBoxArray = document.getElementsByClassName("experience-branchbox");
        for (var i = 0; i < ExperienceBoxArray.length; i++) {
            var ExperienceArray = ExperienceBoxArray[i].getElementsByClassName("experience-container");
            if (ExperienceArray.length > 0) {
                ExperienceArray[ExperienceArray.length - 1].getElementsByClassName("experience-branch")[0].remove();
            }
        }
    }

    // project section animations
    const projectAnimation = () => {

        const projectAnimationTL = gsap.timeline();
        projectAnimationTL
            .add("project-1")
            .to("#project-0-background", { opacity: 0, duration: 0.5 }, "project-1")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 1, duration: 0.5 }, "project-1")
            .to("#project-1-background", { zIndex: 2, opacity: 0.5, duration: 0.5 }, "project-1")
            .add("project-2")
            .to("#project-1-background", { opacity: 0, duration: 0.5 }, "project-2")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 2, duration: 0.5 }, "project-2")
            .to("#project-2-background", { zIndex: 3, opacity: 0.5, duration: 0.5 }, "project-2")
            .add("project-3")
            .to("#project-2-background", { opacity: 0, duration: 0.5 }, "project-3")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 3, duration: 0.5 }, "project-3")
            .to("#project-3-background", { zIndex: 4, opacity: 0.5, duration: 0.5 }, "project-3")
            .add("project-4")
            .to("#project-3-background", { opacity: 0, duration: 0.5 }, "project-4")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 4, duration: 0.5 }, "project-4")
            .to("#project-4-background", { zIndex: 5, opacity: 0.5, duration: 0.5 }, "project-4")
            .add("project-5")
            .to("#project-4-background", { opacity: 0, duration: 0.5 }, "project-5")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 5, duration: 0.5 }, "project-5")
            .to("#project-5-background", { zIndex: 5, opacity: 0.5, duration: 0.5 }, "project-5")
            .add("project-6")
            .to("#project-5-background", { opacity: 0, duration: 0.5 }, "project-6")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 6, duration: 0.5 }, "project-6")
            .to("#project-6-background", { zIndex: 5, opacity: 0.5, duration: 0.5 }, "project-6")
            .add("project-7")
            .to("#project-6-background", { opacity: 0, duration: 0.5 }, "project-7")
            .to(".project-showcase", { xPercent: -(100 * ((window.innerWidth < 768 ? 60 / 50 : 35 / 30))) * 7, duration: 0.5 }, "project-7")
            .to("#project-7-background", { zIndex: 5, opacity: 0.5, duration: 0.5 }, "project-7")
            .add("end")

        ScrollTrigger.create({
            animation: projectAnimationTL,
            trigger: "#project-body",
            start: "top top",
            end: "+=" + (200 * projectData.length),
            scrub: true,
            pin: true,
            snap: {
                snapTo: "labels", // snap to the closest label in the timeline
                duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
                ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
            },
            anticipatePin: 1
        });
    }

    // masthead scrolltrigger animation
    const mastheadScrollTrigger = () => {
        const tl = gsap.timeline();
        tl.addLabel("start")
            .to("#masthead", { transform: "scale(0.65)", duration: 3 }, 0)
            .to("#canvas", { transform: "scale(0.65)", duration: 3 }, 0)
            .to("#background-title-name", { color: "transparent", duration: 1 }, 0)
            .to("#background-title-text", { color: "transparent", duration: 1 }, 0)

        ScrollTrigger.create({
            animation: tl,
            trigger: "#top",
            start: "top top",
            end: "300vh",
            scrub: true
        });
    }

    useEffect(() => {
        // view project
        if (ViewProjectID >= 0) {
            const tl = gsap.timeline();
            tl.addLabel("start")
                .from("#Color-background", { xPercent: 100, duration: 0.3 })
                .from("#View-Project", { xPercent: 100, duration: 0.3 })
                .call(() => { document.getElementById("navbar").style.display = "none";document.getElementById("View-Project").style.transform = "none"; document.getElementById("View-Project").style.position = "absolute"; gsap.to(window, { duration: 0, scrollTo: "#View-Project" }); setPage("Project"); document.getElementById("main").style.display = "none"; document.getElementById("main").style.overflow = "hidden" })
        }
    }, [ViewProjectID])

    useEffect(() => {
        if (page == "main" && LoadingPercentageDisplay >= 100) {
            // exit project
            const tl = gsap.timeline();
            tl.addLabel("start")
                .call(() => { document.getElementById("main").style.display = "block"; document.getElementById("main").style.overflow = "auto"; })
                .to("#View-Project", { xPercent: 100, duration: 0.3 })
                .to("#Color-background", { xPercent: 100, duration: 0.3 })
                .call(() => { document.getElementById("navbar").style.display = "flex"; document.getElementById("View-Project").style.position = "fixed"; setViewProjectID(-1); Resize(); gsap.to(window, { duration: 1, scrollTo: "#project" }); })

        }
    }, [page])

    return (
        <div id="Home">
            <div id="Loadingoverlay">
                <p id="LoadingPercentage">{LoadingPercentageDisplay}%</p>

                <span id="loading-line"></span>
            </div>
            {LoadingPercentageDisplay >= 100 &&
                <div className="main" id="main">
                    <div id='top' />
                    <div id="masthead">
                        <div className='title-container'>
                            <h1 id="title-name" className='title-name'>Yang QuanJun</h1>
                            <p className="title-type">
                                I'm
                                <span id="typed-type"> {CurrentTitleDisplay}</span>
                                <span className="text-cursor">|</span>
                            </p>
                        </div>
                    </div>
                    <div id="about">
                        <div className='heading'>
                            <h1 className='title'>About Me</h1>
                            <span className='line'></span>
                        </div>
                        <div className='about-body'>
                            <div className='resume-photo-container'>
                                <div id='firefox-scrolltrigger-image-filler'></div>
                                <img id='resume-photo' src={resumePhoto} alt="Photo of myself"/>
                            </div>
                            <div className='about-text'>
                                <div className='icons'>
                                    <a href='https://www.linkedin.com/in/yang-quanjun-87b7871a9/' target="_blank"><i className="fab fa-linkedin"></i></a>
                                    <a href='https://github.com/yquanjun' target="_blank"><i className="fab fa-github"></i></a>
                                </div>
                                <p className='about-introduction'>Hello, I'm <span className='about-name'>QuanJun</span></p>
                                <p className='about-title'>a full-time national servicemen (NSFs) in the Singapore Armed Forces (SAF).</p>
                                <p className='about-description'>I started learning to code three years ago and have been hooked on to the idea that so much can be achieved with just a few lines of code. I love exploring around youtube and discovering more about programming. <a onClick={() => { gsap.to(window, { duration: 1, scrollTo: "#project" }); }} className='text-gradient'>Just recently, I discovered template matching from OpenCV and created a bot to automate some of my games.</a></p>
                                <Waypoint
                                    onEnter={() => {
                                        document.getElementsByClassName("about-description")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                    }}
                                    onLeave={() => {
                                        document.getElementsByClassName("about-description")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <div id="experience">
                        <div className='heading'>
                            <h1 className='title'>My Experience</h1>
                            <span className='line'></span>
                        </div>
                        <div className='experience-body'>
                            <div className='education'>
                                <h2 className='experience-titlebox'>
                                    Education
                                </h2>

                                <div className='experience-branchbox'>
                                    <div className='experience-container'>
                                        <div className='experience-branch' >
                                            <Waypoint
                                                onEnter={() => {
                                                    document.getElementsByClassName("experience-branchbox")[0].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(1)';
                                                }}
                                                onLeave={() => {
                                                    document.getElementsByClassName("experience-branchbox")[0].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(0)';
                                                }}
                                            />
                                        </div>
                                        <span className='experience-circle'>
                                            <p className='experience-no'>1</p>
                                        </span>
                                        <span className='experience-text'>
                                            <p className='experience-date'>Mar 2019 - May 2022</p>
                                            <p className='experience-title'>Diploma in Information Technology (Final GPA : 3.892)<span className='experience-subtitle'> - Singapore Polytechnic</span></p>
                                            <ol className='experience-description'>
                                            </ol>
                                        </span>
                                    </div>
                                    <div className='experience-container'>
                                        <div className='experience-branch' >
                                            {/* <Waypoint
                                                onEnter={() => {
                                                    document.getElementsByClassName("experience-branchbox")[0].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(1)';
                                                }}
                                                onLeave={() => {
                                                    document.getElementsByClassName("experience-branchbox")[0].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(0)';
                                                }}
                                            /> */}
                                        </div>
                                        <span className='experience-circle'>
                                            <p className='experience-no'>2</p>
                                        </span>
                                        <span className='experience-text'>
                                            <p className='experience-date'>Jan 2015 - Nov 2018</p>
                                            <p className='experience-title'>O-Level (L1R4 : 14)<span className='experience-subtitle'> - Christ Church Secondary School</span></p>
                                            <ol className='experience-description'>
                                                <li>Boy's Brigade (Staff Sergeant)</li>
                                                <li>Achieved 11th place in Character Quest Competition (Boy's Brigade) in 2016</li>
                                                <li>Participated in Share-A-Gift</li>
                                                <li>Participated in Chingay Parade Singapore 2015 </li>
                                            </ol>
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div className='work'>
                                <h2 className='experience-titlebox'>
                                    Work Experience
                                </h2>

                                <div className='experience-branchbox'>
                                    <div className='experience-container'>
                                        <div className='experience-branch' >
                                            <Waypoint
                                                onEnter={() => {
                                                    document.getElementsByClassName("experience-branchbox")[1].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(1)';
                                                }}
                                                onLeave={() => {
                                                    document.getElementsByClassName("experience-branchbox")[1].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(0)';
                                                }}
                                            />
                                        </div>
                                        <span className='experience-circle'>
                                            <p className='experience-no'>1</p>
                                        </span>
                                        <span className='experience-text'>
                                            <p className='experience-date'>Sep 2021 - May 2022</p>
                                            <p className='experience-title'>Programming Intern <span className='experience-subtitle'> - Convertium Pte Ltd</span></p>
                                            <p className='experience-website'>Worked on websites such as: Lexus, Toyota, SIT, SIM, NTUC Income</p>
                                            <ol className='experience-description'>
                                                <li>Maintaining and updating contents through Content Management Systems such as Adobe Experience Manager and Magento.</li>
                                                <li>Making changes to the layout/design (Frontend).</li>
                                                <li>Creating and updating Backend components.</li>
                                                <li>Testing and Creating documentations.</li>
                                                <li>Creating and maintaining Chrome Extensions to improve work efficiency.</li>
                                            </ol>
                                        </span>
                                    </div>
                                    <div className='experience-container'>
                                        <div className='experience-branch' >
                                            {/* <Waypoint
                                                onEnter={() => {
                                                    document.getElementsByClassName("experience-branchbox")[1].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(1)';
                                                }}
                                                onLeave={() => {
                                                    document.getElementsByClassName("experience-branchbox")[1].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(0)';
                                                }}
                                            /> */}
                                        </div>
                                        <span className='experience-circle'>
                                            <p className='experience-no'>2</p>
                                        </span>
                                        <span className='experience-text'>
                                            <p className='experience-date'>Sep 2018 to Feb 2019</p>
                                            <p className='experience-title'>Retail Crew (part time) <span className='experience-subtitle'> - Bee Cheng Hiang</span></p>
                                            <ol className='experience-description'>
                                                <li>Coordinated with a team to barbeque and prepared high quality products for sale. </li>
                                                <li>Managed the storage and cleaning of the retail shop.</li>
                                                <li>Guided the newer employees when they are in need of help.</li>
                                            </ol>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='experience-skills-container'>
                            <div className='heading'>
                                <h1 className='title'>My Skills</h1>
                                {/* <span className='line'></span> */}
                            </div>
                            <div className='skills-container'>
                                <span className='about-skills'>
                                    <div className='skill-name'>HTML &amp; CSS</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>75%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[0].style.width = document.getElementsByClassName("skill-percentage")[0].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[0].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[0].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[0].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[0].style.left = "0"
                                        }}
                                    />
                                </span>

                                <span className='about-skills'>
                                    <div className='skill-name'>Javascript</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>85%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[1].style.width = document.getElementsByClassName("skill-percentage")[1].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[1].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[1].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[1].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[1].style.left = "0";
                                        }}
                                    />
                                </span>

                                <span className='about-skills'>
                                    <div className='skill-name'>Python</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>70%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[2].style.width = document.getElementsByClassName("skill-percentage")[2].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[2].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[2].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[2].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[2].style.left = "0";
                                        }}
                                    />
                                </span>

                                <span className='about-skills'>
                                    <div className='skill-name'>Java</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>60%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[3].style.width = document.getElementsByClassName("skill-percentage")[3].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[3].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[3].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[3].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[3].style.left = "0";
                                        }}
                                    />
                                </span>
                                <span className='about-skills'>
                                    <div className='skill-name'>Git</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>70%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[4].style.width = document.getElementsByClassName("skill-percentage")[4].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[4].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[4].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[4].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[4].style.left = "0";
                                        }}
                                    />
                                </span>

                                <span className='about-skills'>
                                    <div className='skill-name'>MySQl</div>
                                    <div className='skill-box-container'>
                                        <p className='skill-percentage'>70%</p>
                                        <div className='skill-box'>
                                            <div className='skill-color'></div>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document.getElementsByClassName("skill-color")[5].style.width = document.getElementsByClassName("skill-percentage")[5].innerHTML;
                                            document.getElementsByClassName("skill-percentage")[5].style.left = "calc(" + document.getElementsByClassName("skill-percentage")[5].innerHTML + " - 10px)";
                                        }}
                                        onLeave={() => {
                                            document.getElementsByClassName("skill-color")[5].style.width = "0%";
                                            document.getElementsByClassName("skill-percentage")[5].style.left = "0";
                                        }}
                                    />
                                </span>
                            </div>
                        </div>


                        <div className='experience-certificates-container'>
                            <div className='heading'>
                                <h1 className='title'>My Certificates</h1>
                                {/* <span className='line'></span> */}
                            </div>

                            <div className='certificate-body'>
                                <div className='education'>
                                    <h2 className='experience-titlebox'>
                                        Education
                                    </h2>
                                    <div className='certificate-container'>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>1</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Apr 2021</p>
                                                <p className='experience-title'>Director's Honour Roll AY 2020/2021<span className='experience-subtitle'> - Singapore Polytechnic</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>2</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Sep 2021</p>
                                                <p className='experience-title'>Certificate In IOT (Internet of Things) <span className='experience-subtitle'> - Singapore Polytechnic</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[1].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[1].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[2].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[2].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>3</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in 2021</p>
                                                <p className='experience-title'>Edusave Certificate of Academic Achievement 2021<span className='experience-subtitle'> - Singapore Polytechnic</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[2].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[2].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>

                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>4</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in 2020</p>
                                                <p className='experience-title'>Edusave Merit Bursary 2020<span className='experience-subtitle'> - Singapore Polytechnic</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>

                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[4].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[4].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>5</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in 2016</p>
                                                <p className='experience-title'>STEM INC Young Engineer Award (Silver)<span className='experience-subtitle'> - Christ Church Secondary School</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>

                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(0)';
                                                    }}
                                                /> */}
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>6</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in 2016</p>
                                                <p className='experience-title'>Australian Mathematics Competition Certiﬁcate of Distinction <span className='experience-subtitle'> - Christ Church Secondary School</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[0].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='certificate-course'>
                                    <h2 className='experience-titlebox'>
                                        External Courses
                                    </h2>
                                    <div className='certificate-container'>
                                    <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[0].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>1</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Jan 2022</p>
                                                <p className='experience-title'><a target="_blank" href='https://www.coursera.org/account/accomplishments/certificate/CRAX4RZKCBF4' className='text-gradient'>Linux Fundamentals</a> <span className='experience-subtitle'> - Coursera</span></p>
                                                <p className='experience-description'></p>
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[0].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[1].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>2</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Sep 2021</p>
                                                <p className='experience-title'><a target="_blank" href='https://www.coursera.org/account/accomplishments/certificate/DN8PBUDKSD7X' className='text-gradient'> IT Security: Defense against the digital dark arts</a> <span className='experience-subtitle'> - Coursera</span></p>
                                                <p className='experience-description'></p>
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[1].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[1].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[2].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[2].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>3</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in May 2021</p>
                                                <p className='experience-title'><a target="_blank" href='https://www.coursera.org/account/accomplishments/certificate/YUDTAKNFU4GL' className='text-gradient'>Start the UX Design Process: Empathize, Define, and Ideate</a> <span className='experience-subtitle'> - Coursera</span></p>
                                                <p className='experience-description'></p>
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[2].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[2].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[3].style.transform = 'scaleY(0)';
                                                    }}
                                                />
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>4</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Apr 2021</p>
                                                <p className='experience-title'>Project Management Foundations <span className='experience-subtitle'> - Linkedin Learning</span></p>
                                                <p className='experience-description'></p>
                                                {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[3].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                /> */}
                                            </span>
                                        </div>
                                        <div className='experience-container'>
                                            <div className='experience-branch' >
                                            {/* <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[4].style.transform = 'scaleY(1)';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-branch")[4].style.transform = 'scaleY(0)';
                                                    }}
                                                /> */}
                                            </div>
                                            <span className='experience-circle'>
                                                <p className='experience-no'>5</p>
                                            </span>
                                            <span className='experience-text'>
                                                <p className='experience-date'>Issued in Mar 2021</p>
                                                <p className='experience-title'><a target="_blank" href='https://www.coursera.org/account/accomplishments/certificate/F55HXUF25R36' className='text-gradient'>Foundations of User Experience (UX) Design</a> <span className='experience-subtitle'> - Coursera</span></p>
                                                <p className='experience-description'></p>
                                                <Waypoint
                                                    onEnter={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[4].getElementsByClassName("text-gradient")[0].style.backgroundSize = '100% 3px';
                                                    }}
                                                    onLeave={() => {
                                                        document.getElementsByClassName("certificate-container")[1].getElementsByClassName("experience-title")[4].getElementsByClassName("text-gradient")[0].style.backgroundSize = '0% 3px';
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="project">
                        <div className='heading'>
                            <h1 className='title'>My Non-Client Projects</h1>
                            <span className='line'></span>
                        </div>
                        <div className='scroll-trigger-container'>
                            <div id="project-body" className='project-body'>
                                <div id='project-list'>
                                    <Project id={0} image={projectData[0].image} title={projectData[0].title} type={projectData[0].type} />
                                    <Project id={1} image={projectData[1].image} title={projectData[1].title} type={projectData[1].type} />
                                    <Project id={2} image={projectData[2].image} title={projectData[2].title} type={projectData[2].type} />
                                    <Project id={3} image={projectData[3].image} title={projectData[3].title} type={projectData[3].type} />
                                    <Project id={4} image={projectData[4].image} title={projectData[4].title} type={projectData[4].type} />
                                    <Project id={5} image={projectData[5].image} title={projectData[5].title} type={projectData[5].type} />
                                    <Project id={6} image={projectData[6].image} title={projectData[6].title} type={projectData[6].type} />
                                    <Project id={7} image={projectData[7].image} title={projectData[7].title} type={projectData[7].type} />
                                </div>
                                <div id='project-0-background' className='project-background'>
                                    <img src={projectData[0].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-1-background' className='project-background'>
                                    <img src={projectData[1].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-2-background' className='project-background'>
                                    <img src={projectData[2].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-3-background' className='project-background'>
                                    <img src={projectData[3].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-4-background' className='project-background'>
                                    <img src={projectData[4].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-5-background' className='project-background'>
                                    <img src={projectData[5].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-6-background' className='project-background'>
                                    <img src={projectData[6].image} height={"100%"} width={"100%"} />
                                </div>
                                <div id='project-7-background' className='project-background'>
                                    <img src={projectData[7].image} height={"100%"} width={"100%"} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="contact">
                        <div className='heading'>
                            <h1 className='title'>Contact me</h1>
                            <span className='line'></span>
                        </div>
                        <div className='contact-body'>
                            <h1 className='contact-title'>Let's have <br />a chat.</h1>
                            <form className='contact-form' name='contact' data-netlify="true" method='POST'>
                                <input type="hidden" name="form-name" value="contact" />
                                <div className='form-field-div'>
                                    <input type="text" name='name' id='name' className='form-field' placeholder='Name' required/>
                                </div>
                                <div className='form-field-div'>
                                    <input type="email" name='email' id='email' className='form-field' placeholder='Email Address' required/>
                                </div>
                                <div className='form-field-div'>
                                    <input type="text" name='subject' id='subject' className='form-field' placeholder='Subject' required/>
                                </div>
                                <div className='form-field-div'>
                                    <input type="text" name='message' id='message' className='form-field' placeholder='Message' required/>
                                </div>
                                
                                <div className='form-field-div'>
                                    <input type="checkbox" name='checkbox' id='checkbox' className='form-field checkbox' required/>
                                    <label for="checkbox"> I agree that my submitted data is being collected and stored.</label>
                                </div>
                                <input type='submit' name='submit' value="Send" className='form-submit' />
                            </form>

                        </div>

                        <Wave fill='#4B5D7A'
                            paused={false}
                            options={{
                                height: 20,
                                amplitude: 40,
                                speed: 0.35,
                                points: 3
                            }}
                        />
                    </div>
                </div>
            }
{
    ViewProjectID >= 0 && <>
        <div id='Color-background' />
        <ViewProject setPage={setPage} id={ViewProjectID} image={projectData[ViewProjectID].image} title={projectData[ViewProjectID].title} description={projectData[ViewProjectID].description} tags={projectData[ViewProjectID].tags} />
    </>
}
        </div >
    )
}

export default Home
