// imports
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// Pages
import Home from './pages/Home';
import Header from './pages/Header';
import Canvas from './pages/Canvas';

function App() {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // redirect to /home when there is no path
    if (window.location.pathname != '/home') {
      setRedirect(true);
    } else {
      setRedirect(false);
    }
  }, []);

  return (
    <BrowserRouter forceRefresh>
      {redirect &&
        <Redirect to='/home' />}
        <Route exact path='/home' component={Canvas} />
      {/* <Canvas /> */}

      <Header />

      <Route exact path='/home' component={Home} />
    </BrowserRouter>
  );
}

export default App;
