import React from 'react'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Header = () => {
    return (
        <nav id="navbar" className="navbar navbar-expand-lg navbar-dark sticky-top">
            <div className="container-fluid">
                {/* <a className="navbar-brand" href="#"></a> */}
                <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" onClick={()=>{gsap.to(window, {duration: 1, scrollTo:"#about"});}}>About Me</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" onClick={()=>{gsap.to(window, {duration: 1, scrollTo:"#experience"});}}>Experience</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" onClick={()=>{gsap.to(window, {duration: 1, scrollTo:"#project"});}}>Projects</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" onClick={()=>{gsap.to(window, {duration: 1, scrollTo:"#contact"});}}>Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header
