import React, { useEffect, useState } from 'react'

const Canvas = () => {
    const [width, setWidth] = useState(document.body.clientWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [circleArray, setcircleArray] = useState([]);

    // (Year,Month - 1, Date, hr, min, sec)
    const [UpdateDate, SetUpdateDate] = useState(new Date(2022, 9, 8, 7, 0, 0))
    const [UpdateAgo, SetUpdateAgo] = useState(0)
    var animationFrame;

    class Circle {
        constructor() {
            this.size = 1 + (Math.ceil(Math.random() * 3));
            this.position = {
                y: Math.ceil(Math.random() * height),
                x: Math.ceil(Math.random() * width)
            }
            this.originalPosition = this.position;
            this.angle = (Math.ceil(Math.random() * 360));
            this.speed = 30 + (Math.ceil(Math.random() * 60));
            this.originalDate = new Date();
        }

        updatePosition = () => {
            var date = new Date();
            this.position = {
                x: this.originalPosition.x + (Math.cos(this.angle * (Math.PI / 180)) * (this.speed * ((date.getTime() - this.originalDate.getTime()) / 1000))),
                y: this.originalPosition.y + (Math.sin(this.angle * (Math.PI / 180)) * (this.speed * ((date.getTime() - this.originalDate.getTime()) / 1000)))
            }
            // var canvasWidth = document.getElementById('canvas').width;
            // var canvasHeight = document.getElementById('canvas').height;
            var canvasWidth = window.innerWidth;
            var canvasHeight = window.innerHeight;

            // if circle hit left wall
            if (this.position.x < 0) {

                // circle coming from below
                if (this.angle > 180) {
                    this.angle = 270 + (270 - this.angle);
                } else {
                    this.angle = 90 - (this.angle - 90);
                }
                this.updateOriginal();
            }
            // if circle hit right wall
            if (this.position.x > canvasWidth) {

                // circle coming from below
                if (this.angle > 90) {
                    this.angle = 180 + (360 - this.angle);
                } else {
                    this.angle = 180 - this.angle;
                }

                this.updateOriginal();
            }
            // if circle hit top wall
            if (this.position.y < 0) {

                // circle coming from the left
                if (this.angle > 90) {
                    this.angle = 90 - (this.angle - 270);
                } else {
                    this.angle = 90 + (270 - this.angle);
                }

                this.updateOriginal();
            }
            // if circle hit bottom wall
            if (this.position.y > canvasHeight) {

                // circle coming from the left
                if (this.angle < 90) {
                    this.angle = 270 + (90 - this.angle);
                } else {
                    this.angle = 270 - (this.angle - 90);
                }
                this.updateOriginal();
            }
        }

        updateOriginal = () => {
            this.originalDate = new Date();
            this.originalPosition = this.position;
        }

        drawLines = (index, ctx) => {
            for (var x = 0; x < circleArray.length; x++) {
                if (x != index) {
                    // calculate distance between two circle with pythagoras theorem
                    var xdistance = this.position.x - circleArray[x].position.x;
                    var ydistance = this.position.y - circleArray[x].position.y;

                    var distance = Math.sqrt(xdistance ** 2 + ydistance ** 2);

                    if (distance < 130) {
                        ctx.beginPath();
                        // 80% thickness
                        ctx.lineWidth = (130 / distance) * 0.8;
                        ctx.moveTo(this.position.x, this.position.y);
                        ctx.lineTo(circleArray[x].position.x, circleArray[x].position.y);
                        ctx.stroke();
                    }
                }
            }
        }
    }

    var runcount = 0
    useEffect(() => {
        if (runcount === 0) {
            init();
            runcount++;
            setInterval(function () {
                var MSAgo = new Date() - UpdateDate
                var dayAgo = Math.floor(MSAgo / 86400000)
                var hourAgo = Math.floor(MSAgo / 3600000) % 24
                var minAgo = Math.floor(MSAgo / 60000) % 60
                var SecAgo = Math.floor(MSAgo / 1000) % 60
                var Ago = ""
                if (dayAgo > 0) {
                    if (dayAgo > 1) {
                        Ago += dayAgo + " days "
                    } else {
                        Ago += dayAgo + " day ";
                    }
                }

                if (hourAgo < 10) {
                    Ago += "0" + hourAgo + ":";
                } else {
                    Ago += hourAgo + ":";
                }

                if (minAgo < 10) {
                    Ago += "0" + minAgo + ":";
                } else {
                    Ago += minAgo + ":";
                }

                if (SecAgo < 10) {
                    Ago += "0" + SecAgo;
                } else {
                    Ago += SecAgo;
                }

                SetUpdateAgo(Ago);
            }, 1000);
        }
    }, [])

    const init = () => {
        // only loads after loading screen is over
        if (document.getElementById("Loadingoverlay") != undefined && document.getElementById("Loadingoverlay").className.search("hidden") == -1) {
            setTimeout(() => {
                init();
            }, 100);
        } else {
            setWidth(document.body.clientWidth);
            window.addEventListener('resize', () => {
                setWidth(document.body.clientWidth);
                setHeight(window.innerHeight);
                document.getElementById('canvas').height = window.innerHeight;
                document.getElementById('canvas').width = document.body.clientWidth;

                // circleAmount == number of circles
                var circleAmount;

                if (width < 1000) {
                    circleAmount = 15
                } else if (width < 1500) {
                    circleAmount = 30
                } else {
                    circleAmount = 40
                }

                circleArray.length = 0;
                for (var i = 0; i < circleAmount; i++) {
                    let circle = new Circle();
                    circleArray.push(circle);
                }
                console.log(circleArray)
                draw();
                backgroundStyle();
            })

            // circleAmount == number of circles
            var circleAmount;

            if (width < 1000) {
                circleAmount = 15
            } else if (width < 1500) {
                circleAmount = 30
            } else {
                circleAmount = 40
            }

            for (var i = 0; i < circleAmount; i++) {
                let circle = new Circle();
                circleArray.push(circle);
            }

            draw();

            backgroundStyle();

            // // active tab
            // window.addEventListener('focus', () => {
            //     // reset dates and position so that the inactive time will not be accounted when calculating position
            //     for (var i = 0; i < circleArray.length; i++) {
            //         circleArray[i].updateOriginal();
            //     }
            //     draw();
            //     console.log("active");
            // });


            // window change
            document.addEventListener('visibilitychange', () => {
                if (document.hidden) {
                    // remove updates
                    window.cancelAnimationFrame(animationFrame);
                    console.log("blur");
                }
                else {
                    // reset dates and position so that the inactive time will not be accounted when calculating position
                    for (var i = 0; i < circleArray.length; i++) {
                        circleArray[i].updateOriginal();
                    }
                    draw();
                    console.log("active");
                }
            });

            // // inactive tab
            // window.addEventListener('blur', () => {
            //     // remove updates
            //     window.cancelAnimationFrame(animationFrame);
            //     console.log("blur");
            //     console.log("A")
            // });
        }
    }

    var mouseX, mouseY;

    const draw = (noRepeat) => {
        var canvas = document.getElementById('canvas');
        var ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas

        var grd = ctx.createLinearGradient(0, canvas.height * 0.70, canvas.width * 0.05, 0);
        grd.addColorStop(0.05, "rgba(7, 6, 20, 1)");
        grd.addColorStop(0.35, "rgba(16, 25, 60, 1)");
        grd.addColorStop(0.50, "rgba(10, 75, 59, 1)");
        grd.addColorStop(0.65, "rgba(16, 25, 60, 1)");
        grd.addColorStop(0.95, "rgba(7, 6, 20, 1)");
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = 'rgba(145,145,145,255)';
        ctx.strokeStyle = 'rgba(145,145,145,255)';

        for (var i = 0; i < circleArray.length; i++) {
            ctx.beginPath();
            ctx.arc(circleArray[i].position.x, circleArray[i].position.y, circleArray[i].size, 0, 2 * Math.PI);
            ctx.fill();
            circleArray[i].updatePosition();
            circleArray[i].drawLines(i, ctx);
        }

        if (document.body.clientWidth > 1000) {
            document.onmousemove = handleMouseMove;
            function handleMouseMove(event) {
                var eventDoc, doc, body;

                event = event || window.event; // IE-ism

                // If pageX/Y aren't available and clientX/Y are,
                // calculate pageX/Y - logic taken from jQuery.
                // (This is to support old IE)
                if (event.pageX == null && event.clientX != null) {
                    eventDoc = (event.target && event.target.ownerDocument) || document;
                    doc = eventDoc.documentElement;
                    body = eventDoc.body;

                    event.pageX = event.clientX +
                        (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                        (doc && doc.clientLeft || body && body.clientLeft || 0);
                    event.pageY = event.clientY +
                        (doc && doc.scrollTop || body && body.scrollTop || 0) -
                        (doc && doc.clientTop || body && body.clientTop || 0);
                }

                // Use event.pageX / event.pageY here
                mouseX = event.pageX;
                mouseY = event.pageY;
            }

            ctx.globalCompositeOperation = "destination-out";
            ctx.beginPath();
            ctx.arc(mouseX, mouseY, 100, 0, 2 * Math.PI);
            ctx.fill();

            // rocket WIP
            // var rocket = document.getElementById("rocket-mouse");
            // var RocketStyle = rocket.getBoundingClientRect();

            // if (mouseX + RocketStyle.width >= width) {
            //     rocket.style.left = (mouseX - RocketStyle.width) + "px";
            // } else {
            //     rocket.style.left = (mouseX - (RocketStyle.width/2)) + "px";
            // }
            // if (mouseY + RocketStyle.height >= height) {
            //     rocket.style.top = (mouseY - RocketStyle.height) + "px";
            // } else {
            //    rocket.style.top = (mouseY - (RocketStyle.height/3)) + "px";
            // }

        }

        if (noRepeat !== true) {
            animationFrame = window.requestAnimationFrame(draw);
        }
    }

    const backgroundStyle = () => {
        // adjust background style
        var backgroundTitleName = document.getElementById("background-title-name");
        var NameComputedStyle = backgroundTitleName.currentStyle || window.getComputedStyle(backgroundTitleName);
        document.getElementById("background-title-text").style.width = NameComputedStyle.width;

        var navbarItems = document.getElementsByClassName("nav-link active");
        if (navbarItems[0].getBoundingClientRect().width == 0 && width >= 992) {
            setTimeout(function () { backgroundStyle(); }, 100);
        } else {
            for (var i = 0; i < navbarItems.length; i++) {
                var navbarItemsPosition = navbarItems[i].getBoundingClientRect();

                // remove old background nav links
                if (document.getElementById("background-nav-link-" + i)) {
                    document.getElementById("background-nav-link-" + i).remove();
                }

                var navbarItemDiv = document.createElement("div");
                navbarItemDiv.id = "background-nav-link-" + i;
                navbarItemDiv.className = "background-border-text nav-link";
                navbarItemDiv.style.position = "fixed";
                navbarItemDiv.style.top = navbarItemsPosition.top + "px";
                navbarItemDiv.style.left = navbarItemsPosition.left + "px";
                // console.log("background:" + navbarItemsPosition.left)
                navbarItemDiv.style.height = navbarItemsPosition.height + "px";
                navbarItemDiv.style.width = navbarItemsPosition.width + "px";
                navbarItemDiv.style.color = "white";
                // navbarItemDiv.innerHTML = "<br/>&uarr;<br/>Navbar<br/>item";
                navbarItemDiv.style.textAlign = "center";

                document.getElementById("background").appendChild(navbarItemDiv);
            }

            drawBackgroundCanvas();
        }
    }

    const drawBackgroundCanvas = () => {
        var canvas = document.getElementById('backgroundcanvas');
        var ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas

        ctx.fillStyle = 'black';
        // ctx.fillStyle = '#33B18A';
        ctx.beginPath();
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();

        ctx.fillStyle = 'grey';
        ctx.strokeStyle = 'grey';

        // wireframe dash for .title-container
        var titleContainer = document.getElementsByClassName("title-container")[0]
        var titleContainerPosition = titleContainer.getBoundingClientRect();

        var backgroundTitleName = document.getElementById("background-title-name");
        var backgroundTitleNameComputedStyle = backgroundTitleName.currentStyle || window.getComputedStyle(backgroundTitleName);
        var backgroundTitleNamePosition = backgroundTitleName.getBoundingClientRect();
        var titleType = document.getElementsByClassName("title-type")[0];
        var titleTypeStyle = titleType.currentStyle || window.getComputedStyle(titleType);
        var titleTypePosition = titleType.getBoundingClientRect();

        // vertical dash
        for (var i = 0; i * 14 < canvas.height; i++) {
            ctx.fillRect(titleContainerPosition.left - 2, i * 14, 2, 10);
            ctx.fillRect(titleContainerPosition.left + parseFloat(backgroundTitleNameComputedStyle.width.slice(0, -2)), i * 14, 2, 10);
        }

        // horizontal dash
        for (var i = 0; i * 14 < canvas.width; i++) {
            ctx.fillRect(i * 14, titleContainerPosition.top - 2, 10, 2);
            ctx.fillRect(i * 14, titleContainerPosition.bottom - parseFloat(titleTypeStyle.marginBottom.slice(0, -2)), 10, 2);
            ctx.fillRect(i * 14, backgroundTitleNamePosition.bottom, 10, 2);
            ctx.fillRect(i * 14, titleTypePosition.top - 2, 10, 2);
        }

        // dashes for header
        var navbarItems = document.getElementsByClassName("nav-link active");
        for (var x = 0; x < navbarItems.length; x++) {
            var navbarItemsPosition = navbarItems[x].getBoundingClientRect();

            // vertical dash
            for (var i = 0; i * 14 < canvas.height; i++) {
                ctx.fillRect(navbarItemsPosition.left, i * 14, 2, 10);
                ctx.fillRect(navbarItemsPosition.right - 2, i * 14, 2, 10);
            }

            // horizontal dash
            for (var i = 0; i * 14 < canvas.width; i++) {
                ctx.fillRect(i * 14, navbarItemsPosition.top, 10, 2);
                ctx.fillRect(i * 14, navbarItemsPosition.bottom - 2, 10, 2);
            }
        }
    }

    return (
        <div>
            <div id="background" className="main">
                <canvas id="backgroundcanvas" height={height} width={width}></canvas>
                <div className='title-container'>
                    <div className='background-arrow-box'>
                        <h1 id="background-title-name" className='title-name background-outline-text'>Yang QuanJun</h1>
                        <p className='background-text-arrow'></p>
                    </div>
                    <div className='background-arrow-box'>
                        <p id="background-title-text" className="title-type background-outline-text">
                            I'm
                        </p>
                        <p className='background-text-arrow'></p>
                    </div>
                </div>

                <div id='extra-background'>
                    <p id='extra-background-1'>Time since updated: {UpdateAgo}</p>
                    <p id='extra-background-2'>Hours spent: 30+</p>
                    <p id='extra-background-3'>Code: created from scratch</p>
                    <p id='extra-background-4'>Status: First Draft completed</p>
                </div>
                {/* <i className="fas fa-rocket" id='rocket-mouse'></i> */}
            </div>
            <canvas id="canvas" height={height} width={width}></canvas>
        </div>
    )
}

export default Canvas
