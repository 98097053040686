import React from 'react';
import { useState, useEffect } from 'react'

const Project = (props) => {
    const [marginLeft, setmarginLeft] = useState(0)

    useEffect(() => {
        if (document.body.clientWidth < 768) {
            setmarginLeft((25 + (60 * props.id)) + "vw")
        } else {
            setmarginLeft((35 * (props.id + 1)) + "vw")
        }
    }, [document.body.clientWidth])

    return (
        <div id={"project-" + props.id} className='project-showcase' style={{ marginLeft: marginLeft}}>
            <div className='image-container'>
                <img src={props.image} height={"100%"} width={"100%"} />
                <p>{props.type}</p>
            </div>
            <h2>{props.title}</h2>
        </div>
    )
}

export default Project
