import React from 'react';
import { useState, useEffect } from 'react'

const ViewProject = (props) => {

    const [tags, settags] = useState(props.tags)

    useEffect(() => {
        var totalTags = "";
        for (var i = 0; i < props.tags.length; i++) {
            totalTags += props.tags[i];
            if(i+1<props.tags.length){
                totalTags += ", ";
            }
        }
        settags(totalTags);
    }, [])

    const back = () => {
        props.setPage("main");
    }

    return (
        <div id='View-Project'>
            <div className='heading'>
                <h1 className='title'>{props.title}</h1>
                <span className='line'></span>
            </div>
            <button onClick={() => { back() }}>
                X
            </button>
            <div className='image-container'>
                <img src={props.image} width={"100%"} />
            </div>
            <div className='project-text'>
                <p className='tags'>{tags}</p>
                <p className='description'>{props.description}</p>
                <p>abc</p>
            </div>
        </div>
    )
}

export default ViewProject
